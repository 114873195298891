 <template>
	<section class="section-wrapper" v-background="value.background.path">
		<grid-template cols="4" class="cols">
			<divider-item :class="{ b2b: getPage }" v-for="(item, id) in value.divider" :key="id" :icon="item.divider_icon" :item="`${prefix}.divider.${id}`" v-animate="`opacity${id}`"/>
		</grid-template>
	</section>
</template>

<script>
import DividerItem from '../../components/molecules/cards/DividerItem/DividerItem.vue'
import GridTemplate from '../../templates/GridTemplate.vue'
export default {
	components: {
		DividerItem,
		GridTemplate
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		getPage () {
			return this.$app.page.alias !== 'homepage'
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba($secondary, .82);
		z-index: 0;
	}
	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}

    ::v-deep .cols-4 {
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

}
</style>
