<template>
	<div class="divider">
		<div class="divider-top">
			<base-svg :name="icon"/>
			<cms-text  class="divider-heading" :props="{color: 'white', variant: 'small-heading', marginReset: true }" :value="`${item}.divider_title`"/>
		</div>
		<cms-text :props="{color: 'white', variant: 'paragraph', tag:'div' }" :value="`${item}.divider_content`" class="divider-content"/>
	</div>
</template>
<script>
export default {
	props: {
		item: String,
		icon: String
	}
}
</script>
<style lang="scss" scoped>
.divider {
	display: flex;
	flex-direction: column;
	z-index: 3;
    height: 100%;
    @include media-breakpoint-up(xxl) {
        text-align: left;
    }

	&.b2b{
		.divider-heading {
			@include media-breakpoint-up(xl){
				font-size: 21px;
				font-weight: 900;
			}
		}

		.svg-icon {
			@include media-breakpoint-up(xl) {
				margin-right: 1.5px;
			}
		}

		.divider-top{
			gap: 15px;
		}
	}
	&-top {
		display: flex;
		align-items: flex-start;
		margin-bottom: 2rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(xxl) {
			align-items: center;
		}

		::v-deep .svg-icon {
			height: 2.3rem;
			margin-right: 1.5rem;
			@include media-breakpoint-up(xl) {
				margin-right: 2.5rem;
			}
		}
	}
	::v-deep &-content {
		font-weight: 300;
		strong {
			color: $white;
		}
	}
    ::v-deep &-heading {
        @include media-breakpoint-up(sm) {
            font-size: 1.4rem;
        }
        @include media-breakpoint-up(md) {
            font-size: 1.6rem;
        }
    }
}
</style>
